var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Date *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.takeawayMealRequestForm.date,
                              expression: "takeawayMealRequestForm.date"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "date", name: "", id: "" },
                          domProps: { value: _vm.takeawayMealRequestForm.date },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.takeawayMealRequestForm,
                                "date",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Destination *")
                          ]),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.destinationTypes,
                              placeholder: "Select user structure",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleType",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.takeawayMealRequestForm.location_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.takeawayMealRequestForm,
                                  "location_type",
                                  $$v
                                )
                              },
                              expression:
                                "takeawayMealRequestForm.location_type"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.takeawayMealRequestForm.location_type.id == "external"
                      ? _c("div", { staticClass: "col-lg-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-inputCity" } },
                                [_vm._v("Destination externe *")]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.externalDestinations,
                                  placeholder: "Select user structure",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleDestination",
                                      fn: function(ref) {
                                        var destination = ref.destination
                                        return [
                                          _vm._v(_vm._s(destination.name))
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  900783445
                                ),
                                model: {
                                  value:
                                    _vm.takeawayMealRequestForm
                                      .externalDestination,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.takeawayMealRequestForm,
                                      "externalDestination",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "takeawayMealRequestForm.externalDestination"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.takeawayMealRequestForm.location_type.id == "external"
                      ? _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-grou" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Détails de Destination")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.takeawayMealRequestForm
                                      .externalDestinationTxt,
                                  expression:
                                    "takeawayMealRequestForm.externalDestinationTxt"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value:
                                  _vm.takeawayMealRequestForm
                                    .externalDestinationTxt
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.takeawayMealRequestForm,
                                    "externalDestinationTxt",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.takeawayMealRequestForm.location_type.id == "internal"
                      ? _c("div", { staticClass: "col-lg-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-inputCity" } },
                                [_vm._v("Type de structure *")]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.structureTypes,
                                  placeholder: "Select user structure",
                                  "allow-empty": false
                                },
                                on: { input: _vm.onchangeStructureType },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleStruct",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [_vm._v(_vm._s(type.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3909628126
                                ),
                                model: {
                                  value:
                                    _vm.takeawayMealRequestForm.structure_type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.takeawayMealRequestForm,
                                      "structure_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "takeawayMealRequestForm.structure_type"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.takeawayMealRequestForm.location_type.id == "internal"
                      ? _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-password-input" } },
                                [_vm._v("Structure *")]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.structures,
                                  placeholder: "Select user structure",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleStruct",
                                      fn: function(ref) {
                                        var struct = ref.struct
                                        return [_vm._v(_vm._s(struct.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4101083870
                                ),
                                model: {
                                  value: _vm.takeawayMealRequestForm.structure,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.takeawayMealRequestForm,
                                      "structure",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "takeawayMealRequestForm.structure"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "formrow-password-input" } },
                          [_vm._v("Motif de la demande *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.takeawayMealRequestForm.reason,
                              expression: "takeawayMealRequestForm.reason"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.takeawayMealRequestForm.reason
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.takeawayMealRequestForm,
                                "reason",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Imputation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.takeawayMealRequestForm.analyticAccount,
                              expression:
                                "takeawayMealRequestForm.analyticAccount"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.takeawayMealRequestForm.analyticAccount
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.takeawayMealRequestForm,
                                "analyticAccount",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _c(
                    "div",
                    [
                      _vm._l(_vm.takeawayMealRequestForm.meals, function(
                        requestMeals,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: requestMeals.id, staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group col-lg-4" },
                              [
                                _c("label", { attrs: { for: "resume" } }, [
                                  _vm._v("Repas")
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "name",
                                    options: _vm.mealsTypes,
                                    placeholder: "Selectionner un repas",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "singleMealType",
                                        fn: function(ref) {
                                          var mealType = ref.mealType
                                          return [_vm._v(_vm._s(mealType.name))]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: requestMeals.meal,
                                    callback: function($$v) {
                                      _vm.$set(requestMeals, "meal", $$v)
                                    },
                                    expression: "requestMeals.meal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "form-group col-lg-3" }, [
                              _c("label", { attrs: { for: "subject" } }, [
                                _vm._v("Qty")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: requestMeals.qty,
                                    expression: "requestMeals.qty"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "subject",
                                  type: "number",
                                  min: "1"
                                },
                                domProps: { value: requestMeals.qty },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      requestMeals,
                                      "qty",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "form-group col-lg-4" }, [
                              _c("label", { attrs: { for: "message" } }, [
                                _vm._v("Observations")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: requestMeals.description,
                                    expression: "requestMeals.description"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "subject", type: "text" },
                                domProps: { value: requestMeals.description },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      requestMeals,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-lg-1 align-self-center" },
                              [
                                _vm.takeawayMealRequestForm.meals.length > 1 &&
                                index != 0
                                  ? _c(
                                      "a",
                                      { attrs: { href: "javascript:void(0)" } },
                                      [
                                        _c("i", {
                                          staticClass: "bx bx-trash-alt",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteRow(index)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      }),
                      _c("input", {
                        staticClass: "btn btn-success mt-3 mt-lg-0",
                        attrs: { type: "button", value: "Ajouter" },
                        on: { click: _vm.AddformData }
                      })
                    ],
                    2
                  ),
                  _c("br"),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Commentaires")
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.takeawayMealRequestForm.notes,
                              expression: "takeawayMealRequestForm.notes"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { cols: "30", rows: "4" },
                          domProps: {
                            value: _vm.takeawayMealRequestForm.notes
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.takeawayMealRequestForm,
                                "notes",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          disabled: _vm.$v.takeawayMealRequestForm.$invalid,
                          type: "submit"
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }